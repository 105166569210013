import React from "react";
import { Button } from "react-bootstrap";
import "./TagButton.css";

const TagButton = ({ item, className }) => {
  return (
    <Button className={`customBtn ${className} text-white border-0`}>
      {item}
    </Button>
  );
};

export default TagButton;
