import React from "react";
import { Col } from "react-bootstrap";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagButton from "./TagButton/TagButton";
import {
  faLocationDot,
  faLink,
  faMap,
  faPaperPlane,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import "./color.css";

const CompanyInfoPanel = ({ company, open, onClose }) => {
  if (!company) {
    return null;
  }

  const {
    name,
    description,
    logo,
    booth,
    link,
    industry,
    target_years,
    target_programs,
    tier,
    location,
    job_link,
  } = company;

  const screenDivisionInt = 4;

  const panelItems = (items, type) => (
    <div className="d-flex align-items-center flex-wrap">
      {items.map((item) => {
        let colorClass;
        if (type === "industry") {
          colorClass = "industryColor";
        } else if (type === "year") {
          colorClass = "yearColor";
        } else if (type === "tier") {
          colorClass = "tierColor";
        } else {
          colorClass = "disciplineColor";
        }
        return (
          <div key={item} className="mx-2 my-1">
            <TagButton item={item} className={colorClass} />
          </div>
        );
      })}
    </div>
  );


  const createPanelItemsWithTitles = (panelData) => {
    return panelData.map((data, index) => {
      return data.items && data.items.length > 0 ? (
        <div key={index} className="d-flex justify-content-start row">
          <div className="col-md-1">
            <strong>{data.title}:</strong>
          </div>
          <div className="col-md-9">{panelItems(data.items, data.type)}</div>
        </div>
      ) : null;
    });
  }

  return (
    <BottomSheet
      open={open}
      onDismiss={onClose}
      blocking={false}
      snapPoints={({ maxHeight }) => [maxHeight / screenDivisionInt, maxHeight]}
      defaultSnap={({ snapPoints }) => snapPoints[0]}
      className="topPanelBackgroundColor"
      header={
        <div className="d-flex justify-content-between align-items-center">
          <FontAwesomeIcon
            onClick={onClose}
            icon={faChevronDown}
            className="backButtonIcon button-custom rounded-4"
          />
          <h1 className="fs-2 fw-bold m-auto text-center">
            {name}
          </h1>
          {job_link && (
            <a
              href={job_link}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2 backButtonIcon button-custom rounded-4 btn btn-outline fw-bold rounded-pill"
              style={{ color: "var(--Gray-button-text)" }}
            >
              Apply <FontAwesomeIcon icon={faPaperPlane} />
            </a>
          )}
        </div>
      }
    >
      <div className="topPanelBackgroundColor topPanelBorder">
        <div className="row mx-2 my-2 align-items-end">
          <div className="col col-sm-8 col-md-10">
            {createPanelItemsWithTitles([
              { title: "Sponsor Tier", items: tier, type: "tier" },
              { title: "Industry", items: industry, type: "industry" },
            ])}
          </div>
          <div className="col col-sm-4 col-md-2 d-flex justify-content-end align-self-center">
            <div style={{ width: "80%", overflow: "hidden" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
          <div className="col-md-10">
            {createPanelItemsWithTitles([
              { title: "Year", items: target_years, type: "year" },
              { title: "Discipline", items: target_programs, type: "discipline" },
            ])}
          </div>
        </div>
        <hr className="horizontal-line" />
      </div>
      {booth && (
        <Col
          className="d-flex fw-bold"
          style={{
            backgroundColor: "var(--Panel-color)",
          }}
        >
        </Col>
      )}
      {location && (
        <Col
          className="d-flex"
          style={{
            backgroundColor: "var(--Panel-color)",
          }}
        >
          <div className="d-flex flex-column m-4">
            <div>
              <FontAwesomeIcon
                icon={faMap}
                style={{ color: "var(--YNCN-red)" }}
              />{" "}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  location
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                {location}
              </a>
            </div>
          </div>
        </Col>
      )}

      {description && (
        <Col
          className="d-flex"
          style={{
            backgroundColor: "var(--Panel-color)",
          }}
        >
          <div className="m-4">{description}</div>
        </Col>
      )}
      <Col className="m-2 d-flex justify-content-center">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="button-custom btn btn-outline-danger fw-bold rounded-pill"
          style={{ width: "40%", color: "var(--Gray-button-text)" }}
        >
          <FontAwesomeIcon icon={faLink} /> COMPANY WEBSITE
        </a>
      </Col>
    </BottomSheet>
  );
};

export default CompanyInfoPanel;
