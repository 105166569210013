import React, { useState, useEffect } from "react";

const zoomThresholds = {
  10: 0.5,
  15: 0.75,
  20: 1.0,
  25: 1.1,
  100: 1.15,
};

function convert_to_safe_id(string) {
  return string.replace(/\W/g, "_").toLowerCase();
}

function determine_text_styling(booth, num_lines) {
  const { rotation, width, height } = booth;
  if (
    (rotation >= 0 && rotation < 30) ||
    (rotation >= 330 && rotation <= 360)
  ) {
    return {
      x: width / 2,
      y: -10 - 8 * num_lines,
      align: "middle",
    };
  } else if (rotation >= 30 && rotation < 60) {
    return {
      x: 40,
      y: -5 * num_lines,
      align: "start",
    };
  } else if (rotation >= 60 && rotation < 120) {
    return {
      x: 45,
      y: height / 2 - 5 * num_lines,
      align: "start",
    };
  } else if (rotation >= 120 && rotation < 150) {
    return {
      x: 40,
      y: height - 5 * num_lines,
      align: "start",
    };
  } else if (rotation >= 150 && rotation < 210) {
    return {
      x: width / 2,
      y: height + 5,
      align: "middle",
    };
  } else if (rotation >= 210 && rotation < 240) {
    return {
      x: 10,
      y: height,
      align: "end",
    };
  } else if (rotation >= 240 && rotation < 300) {
    return {
      x: 0,
      y: height / 2 - 5 * num_lines,
      align: "end",
    };
  } else {
    return {
      x: 0,
      y: -10,
      align: "end",
    };
  }
}

function format_text_as_multiline(text) {
  return text.split(" ").reduce((lines, word) => {
    if (lines.length < 1) {
      lines.push(word);
      return lines;
    }
    const last_word = lines[lines.length - 1];
    if (last_word.length + 1 + word.length > 14) lines.push(word);
    else lines[lines.length - 1] += ` ${word}`;
    return lines;
  }, []);
}

function Booth({ company, onClick, zoomLevel, setZoomLevel }) {
  const { booth, name } = company;

  const [showComponent, setShowComponent] = useState(false);
  const [hovering, setHovering] = useState(false);

  const fontSize = `${(1 / zoomLevel) * 10}px`;
  const textOutlineSize = `${(1 / zoomLevel) * 2}px`;
  const lineSeparation = `${Math.log10(-1 * (0.5 * zoomLevel - 2.5)) + 1}em`;

  const textStyle = {
    visibility: `${showComponent ? "visible" : "hidden"}`,
    transformOrigin: "center",
    transform: `translate(${booth.x}px, ${booth.y}px)`,
    transformBox: "fill-box",
    fill: "#333333",
    fontSize: fontSize,
    fontWeight: `600`,
    paintOrder: "stroke",
    stroke: "#FFFFFF",
    strokeWidth: textOutlineSize,
    strokeLinecap: "butt",
    strokeLinejoin: "miter",
  };

  useEffect(() => {
    if (hovering) {
      setShowComponent(true);
      return;
    }

    const length = name ? name[0].length : 0;
    const zoom = zoomLevel;
    let show = false;

    for (const key in zoomThresholds) {
      const value = zoomThresholds[key];
      if (length <= key && zoom >= value) {
        setShowComponent(true);
        show = true;
        break;
      }
    }
    if (!show) {
      setShowComponent(false);
    }
  }, [zoomLevel, name, hovering]);

  const display_lines = name[0] ? format_text_as_multiline(name[0]) : [];
  const text_formatting = determine_text_styling(
    company.booth,
    display_lines.length
  );

  return (
    <g
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <rect
        onClick={() => {onClick(company)}}
        onTouchEnd={() => onClick(company)}
        id={`booth-${booth.id}-${convert_to_safe_id(name[0] ?? "unnamed")}`}
        height={booth.height}
        width={booth.width}
        style={{
          pointerEvents: "visible",
          transformBox: "fill-box",
          transformOrigin: "center",
          transform: `translate(${booth.x}px, ${booth.y}px) rotate(${booth.rotation}deg)`,
        }}
        fill={company.visible ? "rgb(260 133 114)" : "rgb(100 110 113)"}
        rx={"5"}
      />
      <text
        style={textStyle}
        y={text_formatting.y}
        textAnchor={text_formatting.align}
      >
        {display_lines &&
          display_lines.map((word, index) => (
            <tspan key={index} x={text_formatting.x} dy={lineSeparation}>
              {word}
            </tspan>
          ))}
      </text>
    </g>
  );
}

export default Booth;
