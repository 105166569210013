function TutorialSteps() {
    return [
        {
            content: <div>Welcome to the YNCN Career Fair Map!</div>,
            placement: "center",
            target: "body",
        },
        {
            content: <div><strong>Search</strong> for booths by company <strong>name, year, or discipline!</strong></div>,
            target: "#searchBar",
        },
        {
            content: <div><strong>Filter</strong> booths by your <strong>year of study</strong>, or by each company's targetted <strong>discipline</strong>!</div>,
            target: "#companyFilters",
        },
        { // This step relies on an existing booth target, which changes year by year depending on the career fair company list
            content: <div>Click on a booth for <strong>more information</strong> on the company!</div>,
            ///change "target" according to a booth id available for the next career fair
            //ex: #booth-23-manulife
            target: "", 
            placement: "right",
            disableScrolling: false
        },
    ]
}

export default TutorialSteps;
