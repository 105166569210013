import HelpDesk from "../../assets/icons/help_desk.svg";
import PhotoBooth from "../../assets/icons/photo_booth.svg";
import QuietRoom from "../../assets/icons/quiet_room.svg";
import Workshops from "../../assets/icons/workshops.svg";
import CoatCheck from "../../assets/icons/coat_check.svg";
import Exit from "../../assets/icons/exit.svg";

export const interactiveIconInfo = [
    {
        title: "Help Desk & Peer Advisors",
        icon: HelpDesk,
        description: "Come by our help desk to ask any questions or receive help on how to navigate \
        the career fair! At our help desk, we also have peer advisors from the accessibility office \
        available to support you throughout the day.",
        x: 555,
        y: 935,
        width: 40,
        height: 40,
        delay: 100,
    },
    {
        title: "Photo Booths",
        icon: PhotoBooth,
        description: "Need a professional head shot photo  to really take your LinkedIn's \
        professionalism to the next level? Or maybe you'd like a nice photo for the career fair \
        memories! Come check out our photo booth to get your photo taken!",
        x: 153,
        y: 1117,
        width: 50,
        height: 50,
        delay: 300,
    },
    {
        title: "Workshop",
        icon: Workshops,
        description: "If you have been accepted into a workshop held by one of our Gold+ sponsors, \
        come join them in this space and enjoy their specially crafted workshop content!",
        x: 768,
        y: 1117,
        width: 50,
        height: 50,
        delay: 500,
    },
    {
        title: "Quiet Room",
        icon: QuietRoom,
        description: "Need a moment away from the busy crowd at the career fair? Come by and take a \
        relaxing break in our quiet room before going back out there to network with more companies!",
        x: 240,
        y: 1117,
        width: 50,
        height: 50,
        delay: 400,
    },
    // {
    //     title: "Coat Check",
    //     icon: CoatCheck,
    //     description: "Come drop off your coats at our coat check so that you can walk around \
    //     freely to meet and network with everyone!",
    //     x: 45,
    //     y: 1052,
    //     width: 50,
    //     height: 50,
    //     delay: 0,
    // },
];

export const exitIconInfo = {
    title: "Exit",
    icon: Exit,
    description: "Are you sure you want to exit the map and return to our homepage?",
    x: 484,
    y: 1108,
    width: 63,
    height: 86,
    delay: 200,
};