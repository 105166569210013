import React from "react";
import './InteractiveIcon.css';

function InteractiveIcon({info, onClick, highlight}) {
    return (
        <g
            style={{
                pointerEvents: "visible",
                transformBox: "fill-box",
                transformOrigin: "center",
                transform: `translate(${info.x}px, ${info.y}px)`,
            }}
            onClick={() => onClick(info)}
            onTouchEnd={() => onClick(info)}
        >
            <g
                className="interactive-icon"
                style={{
                    animationDelay: `${info.delay}ms`,
            }}>
                <image
                    href={info.icon}
                    height={info.height}
                    width={info.width}
                />
                <svg 
                    className="icon-highlight-wrapper"
                    style={{visibility: highlight ? "visible" : "hidden"}}
                    width={info.width}
                    height={info.height}
                >
                    <rect className="icon-highlight-rect" width={info.width} height={info.height} />
                </svg>
            </g>
        </g>
    );
}

export default InteractiveIcon;
