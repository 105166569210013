import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import './SearchDropdown.css';

/**
 * Returns an interactive search dropdown component.
 * 
 * @param {Array} companies List of companies.
 * @param {boolean} display Dropdown display variable.
 * @param {function} onDropdownClick Callback for clicks on dropdown entries.
 * @returns Search dropdown component.
 */
function SearchDropdown({ companies, display, onDropdownClick }) {

    /**
     * Handle the case where the user clicks on a dropdown entry.
     * 
     * @param {import('react').SyntheticEvent} event Synthetic event.
     * @returns {void}
     */
    const handleDropdownClick = (event) => {
        const index = +event.target.value;
        const company = companies[index];
        onDropdownClick(company);
    };

    return (
        <div style={{display: display ? 'block' : 'none'}} className='search-dropdown'>
            <Container className='searchdropdown-container'>
                <ListGroup>
                    <ListGroup.Item value='0' style={{display: companies[0] ? 'block' : 'none'}} action onMouseDown={handleDropdownClick}>
                        <i className='fa fa-map-marker' aria-hidden='true'></i> {companies[0]?.name?.toString()}
                    </ListGroup.Item>
                    <ListGroup.Item value='1' style={{display: companies[1] ? 'block' : 'none'}} action onMouseDown={handleDropdownClick}>
                        <i className='fa fa-map-marker' aria-hidden='true'></i> {companies[1]?.name?.toString()}
                    </ListGroup.Item>
                    <ListGroup.Item value='2' style={{display: companies[2] ? 'block' : 'none'}} action onMouseDown={handleDropdownClick}>
                        <i className='fa fa-map-marker' aria-hidden='true'></i> {companies[2]?.name?.toString()}
                    </ListGroup.Item>
                </ListGroup>
            </Container>
        </div>
    );
}

export default SearchDropdown;
