import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import "./ConfirmationModal.css";

const ConfirmationModal = ({ show, handleClose, handleConfirm, info }) => {
  if (!info) {
    return;
  }

  return (
    <>
      <Modal
        className="partner-popup"
        size="lg"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>          
          <Row className="justify-content-center">
            <Col className="text-center" xs="auto">
              {info.description}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button className="m-4 yncn-yes" onClick={() => handleConfirm()}>Yes</Button>{' '}
              <Button className="m-4 yncn-no" onClick={() => handleClose()}>No</Button>{' '}
            </Col>
          </Row>
          
        </Modal.Body>

      </Modal>
    </>
  );
};

export default ConfirmationModal;