import React, { useEffect, useRef, useState } from "react";
import { ReactSVGPanZoom, INITIAL_VALUE, TOOL_AUTO } from "react-svg-pan-zoom";
import { useWindowSize } from "@react-hook/window-size";
import carluSVG from "../assets/map/map.svg";

//TODO: figure out how to get bounding box of child svgs
const BOOTH_WIDTH = 1000;
const BOOTH_HEIGHT = 1200;

function SvgMap({ onZoomChange, children }) {
  const groupRef = useRef(null);
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_AUTO);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [width, height] = useWindowSize({
    initialWidth: 400,
    initialHeight: 400,
  });

  //Centers the map on load
  useEffect(() => {
    Viewer.current.fitToViewer("center", "center");
  }, []);

  return (
    <>
      <ReactSVGPanZoom
        ref={Viewer}
        width={width}
        height={height}
        scaleFactorMax={1.7}
        scaleFactorMin={0.3}
        background="rgb(236,244,251)"
        tool={tool}
        onChangeTool={(tool) => setTool(tool)}
        value={value}
        onChangeValue={(newValue) => {
          if (value.a !== newValue.a) {
            // Check if the scale factor has changed
            onZoomChange(newValue);
          }
          setValue(newValue);
        }}
        detectAutoPan={false}
        toolbarProps={{ position: "none" }}
        miniatureProps={{ position: "none" }}
        onZoom={(e) => onZoomChange(e)}
        onPan={(e) => onZoomChange(e)}
      >
        <svg width={BOOTH_WIDTH} height={BOOTH_HEIGHT}>
          <g ref={groupRef}>
            <image href={carluSVG} height={BOOTH_HEIGHT} width={BOOTH_WIDTH} />
            {children}
          </g>
        </svg>
      </ReactSVGPanZoom>
    </>
  );
}

export default SvgMap;
