import React from "react";
import { Col } from "react-bootstrap";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import './IconInfoPanel.css';

const IconInfoPanel = ({ iconInfo, open, onClose }) => {
  if (!iconInfo) {
    return;
  }

  const screenDivisionInt = 4;
  return (
    <BottomSheet
      open={open}
      onDismiss={onClose}
      blocking={false}
      snapPoints={({ maxHeight }) => [maxHeight / screenDivisionInt, maxHeight]}
      defaultSnap={({ snapPoints }) => snapPoints[0]}
      className="topPanelBackgroundColor"
      header={
        <div className="d-flex justify-content-between align-items-center">
          <FontAwesomeIcon
            onClick={onClose}
            icon={faChevronDown}
            className="backButtonIcon button-custom rounded-4"
          />
          <h1 className="fs-2 fw-bold m-auto text-center">
            {iconInfo.title}
          </h1>
        </div>
      }
    >
      {iconInfo.description && (
        <Col
          className="d-flex"
          style={{
            backgroundColor: "var(--Panel-color)",
          }}
        >
          <div className="icon-description">{iconInfo.description}</div>
        </Col>
      )}
    </BottomSheet>
  );
};

export default IconInfoPanel;
