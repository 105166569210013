import React, { useState, useEffect } from "react";
import Joyride from "react-joyride";
import { Alert } from "react-bootstrap";
import TutorialSteps from "./TutorialSteps";
import "./TutorialStyling.css";

function Tutorial() {
    const [returningUser] = useState(localStorage.getItem("returningUser") === "true");

    const TutorialProgress = (
        size,
        current
    ) => {
        const ProgressPoints = []
        for (let i = 0; i < size; i++) {
            if (i === current) {
                ProgressPoints[i] = <div style={{ width: 10, height: 10, left: i * 13, top: 0, position: 'absolute', background: 'white', borderRadius: 3 }} />
            } else {
                ProgressPoints[i] = <div style={{ width: 10, height: 10, left: i * 13, top: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0.60)', borderRadius: 3 }} />
            }
        }
        return (
            <div className="progress-points">
                {ProgressPoints}
            </div>
        )
    }

    const Tooltip = ({
        continuous,
        index,
        step,
        size,
        backProps,
        primaryProps,
        tooltipProps,
        isLastStep
    }) => {
        useEffect(() => {
            if (!returningUser && isLastStep) {
                // Mark the user as a returning user in local storage
                localStorage.setItem("returningUser", "true");
            }
        }, [returningUser]);

        return (
            <Alert {...tooltipProps} className="tutorial">
                <div className="tutorial__content">{step.content}</div>
                {continuous && TutorialProgress(size, index)}
                <div {...primaryProps} className="tutorial__next">
                    {isLastStep ? 'END' : 'NEXT'}
                </div>
                {index > 0 && (<div {...backProps} className="tutorial__prev">PREV</div>)}
            </Alert>
        )

    };

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: TutorialSteps()
    });

    return (
        <>
            {!returningUser &&
                <Joyride
                    continuous
                    callback={() => { }}
                    run={run}
                    steps={steps}
                    disableOverlayClose
                    hideCloseButton
                    disableCloseOnEsc
                    disableScrolling
                    showProgress
                    tooltipComponent={Tooltip}
                    styles={{
                        options: {
                            arrowColor: '#4FB1BA',
                            textColor: 'black',

                        },
                    }}
                />
            }
        </>
    );
}

export default Tutorial;