/**
 * Returns a Levenshtein distance sorted list of matching companies 
 * from least to greatest based on the companies list and query.
 * 
 * @param {Array} companies The list of provided companies on the map.
 * @param {string} query The user query.
 * @returns {Array} A sorted list of closely matched companies.
 */
function SearchAlgorithm(companies, query) {
    if (query !== '') {
        const TOTAL = 3;
        const lowerQuery = query.toLowerCase();
        const levenshteinMatching = (company) => {
            let score = company.name.toString().length > query.length ? company.name.toString().length : query.length;
            let searchNames = company.name.toString().toLowerCase().split(' ');
            searchNames.push(company.name.toString().toLowerCase());
            for (const name of searchNames) {
                score = Math.min(score, Levenshtein(lowerQuery, name));
                if (name.includes(lowerQuery)) {
                    score -= 3;
                }
            }
            return [score, company];
        }
        const isMatching = (levenshteinCompany) => {
            return levenshteinCompany[0] < TOTAL;
        };
        const filteredCompanies = companies.map(levenshteinMatching).filter(isMatching);
        const selectedCompanies = MinSort(filteredCompanies);
        return selectedCompanies;
    } else {
        return [];
    }
}


/**
 * Returns the Levenshtein distance of the query and target strings.
 * 
 * @param {string} query The user query.
 * @param {string} target The target company.
 * @returns {number} The Levenshtein distance.
 */
function Levenshtein(query, target) {
    const tracker = Array(target.length + 1).fill(-1).map(() => Array(query.length + 1).fill(-1));
    for (let col = 0; col <= query.length; col += 1) {
        tracker[0][col] = col;
    }
    for (let row = 0; row <= target.length; row += 1) {
        tracker[row][0] = row;
    }
    for (let row = 1; row <= target.length; row += 1) {
        for (let col = 1; col <= query.length; col += 1) {
            tracker[row][col] = Math.min(
                tracker[row][col - 1] + 1,
                tracker[row - 1][col] + 1,
                tracker[row - 1][col - 1] + (query[col - 1] !== target[row - 1] ? 1 : 0),
            );
        }
    }
    return tracker[target.length][query.length];
}


/**
 * Returns the sorted list of companies from least to greatest.
 * 
 * @param {Array} companies List of companies.
 * @returns {Array} Sorted list of companies from least to greatest.
 */
function MinSort(companies) {
    const sorted = [...companies].sort((a, b) => {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] > b[0]) {
            return 1;
        }
        return 0;
    });
    const removeDistance = levenshteinCompany => levenshteinCompany[1];
    return sorted.map(removeDistance);
}

export default SearchAlgorithm;
